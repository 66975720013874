import React from 'react';

import { Layout } from 'containers';
import {
  ContactUsForm,
} from 'components';

const LateRequest = () => (
  <Layout page="late-request">
    <section className="l-section">
      <div className="l-container">
        <div>
          <h2 className="l-late-request__title c-heading c-heading--h2">Request a Booking</h2>
          <p>Thank you for your interest in Haka Tours. As the departure date of your requested tour is within 30 days, our team need to ensure our partners and suppliers have the availability to secure your booking.</p>
          <p>Please complete the following form and we will respond within 72 hours to confirm your request.</p>
          <br />
        </div>
        <div className="l-contact-us__grid">
          <ContactUsForm checkboxSection={false} messageLabel="Message (please mention the specific tour name and departure date of interest)" />
        </div>
      </div>
    </section>
  </Layout>
);

export default LateRequest;
